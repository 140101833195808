<template>
  <div>
    <window-header></window-header>
    <nav-form></nav-form>
    <section>
      <article style="height:395px">
        <field
          name="delegacion_id"
          widget="m2o"
          label="Deleg."
          width="165px"
          style="top:9px; left:10px;"
          :readonly="mode == 'edit'"
          inputalign="center"
          fontweight="bold"
        />
        <field
          name="ndoc"
          widget="char"
          searchable
          label="Nº Documento"
          width="110px"
          style="top:9px; left:189px;"
        />
        <field
          name="fecha"
          widget="date"
          type="date"
          default="today"
          label="Fecha"
          width="100px"
          style="top:9px; left:307px;"
        />
        <field
          name="almacen_id"
          widget="m2o"
          label="Almacen"
          width="235px"
          style="top:9px; left:415px;"
          :readonly="mode == 'edit'"
          :filter="
            formData.delegacion_id
              ? [['delegacion_id', '=', formData.delegacion_id.codigo]]
              : null
          "
          fontweight="bold"
        />
        <r-tabs
          ref="main-tabs"
          :wt="formData.wt"
          style="top:55px;height:340px;"
          :buttons="['General', 'Líneas']"
        >
          <div class="tab">
            <field
              name="seleccion"
              widget="select"
              label="Selección de artículos"
              :options="{
                Articulo: 'articulo_id',
                Familia: 'familia_id',
                Sección: 'seccion_id',
                Proveedor: 'proveedor_id',
                Marca: 'marca_id',
                Global: 'global'
              }"
              default="articulo"
              width="150px"
              style="top:40px; left:10px;"
              :readonly="mode == 'edit' || !formData.almacen_id"
              @change="onChangeSeleccion"
            />
            <field
              name="familia_id"
              widget="m2o"
              label="Familia"
              width="165px"
              style="top:40px; left:175px;"
              :readonly="mode == 'edit' || !formData.almacen_id"
              v-show="formData.seleccion == 'familia_id'"
              @change="refreshLineas"
            />
            <field
              name="seccion_id"
              widget="m2o"
              label="Sección"
              width="165px"
              style="top:40px; left:175px;"
              :readonly="mode == 'edit' || !formData.almacen_id"
              v-show="formData.seleccion == 'seccion_id'"
              @change="refreshLineas"
            />
            <field
              name="proveedor_id"
              widget="m2o"
              label="Proveedor"
              width="165px"
              style="top:40px; left:175px;"
              labelProp="nombre_comercial"
              :readonly="mode == 'edit' || !formData.almacen_id"
              v-show="formData.seleccion == 'proveedor_id'"
              @change="refreshLineas"
            />
            <field
              name="marca_id"
              widget="m2o"
              label="Marca"
              width="165px"
              style="top:40px; left:175px;"
              :readonly="mode == 'edit' || !formData.almacen_id"
              v-show="formData.seleccion == 'marca_id'"
              @change="refreshLineas"
            />
            <field
              name="observaciones"
              widget="text"
              label="Observaciones"
              width="636px"
              height="100px"
              style="top:160px; right:10px;"
            />
          </div>
          <div class="tab">
            <field
              ref="lineas"
              name="lineas"
              widget="handsontable"
              :height="295"
              :width="655"
              :minRows="12"
              style="top:0px; left:0px; width: 660px;"
              customprop="docs_customprop"
              :readonly="mode == 'edit'"
              :columns="[
                { name: 'ean13', header: '|||||||||', readOnly: true },
                {
                  name: 'articulo_id',
                  header: 'Cód.',
                  readOnly: true,
                  noOpen: true,
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                  showCode: false
                },
                { name: 'nombre', header: 'Artículo', readOnly: true },
                {
                  name: 'unidades_real',
                  header: 'Unid. reales',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                },
                {
                  name: 'peso_real',
                  header: 'Peso. reales',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                },
                {
                  name: 'unidades_teorico',
                  header: 'Unid. teórico',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                },
                {
                  name: 'peso_teorico',
                  header: 'Peso teórico',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                },
                {
                  name: 'unidades_dif',
                  header: 'Unid. discrep.',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  renderer: rendererRojoNegativo
                },
                {
                  name: 'peso_dif',
                  header: 'Peso discrep.',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  renderer: rendererRojoNegativo
                }
              ]"
            />
          </div>
        </r-tabs>
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import Handsontable from "handsontable";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function() {
    var self = this;
    return {
      title: "Regularización de inventario",
      dbAdapter: "regularizacion",
      primary: "ndoc",
      sequence: { name: "regularizacion" },
      defaultData: {
        delegacion_id: self.app.config.delegacion_defecto || null,
        almacen_id: self.app.config.almacen_defecto || null,
        fecha: new Date().yyyymmdd(),
        wt: 0
      },
      fields: ["wt"]
    };
  },
  methods: {
    refreshLineas() {
      var self = this;
      if (self.formData.seleccion == "global") var conditions = [];
      else if (!self.formData.seleccion) {
        self.$set(self.formData, "lineas", []);
        return;
      } else {
        if (!self.formData[self.formData.seleccion]) {
          self.$set(self.formData, "lineas", []);
          return;
        }
        conditions = [
          self.formData.seleccion,
          "=",
          self.formData[self.formData.seleccion].codigo
        ];
      }
      window.DB.getList("articulo", {
        filter: conditions,
        fields: [
          "ean13",
          "codigo",
          "nombre",
          {
            name: "inventario",
            fields: [
              "wt",
              "unidad",
              "peso",
              { name: "almacen_id", fields: ["codigo"] }
            ]
          }
        ]
      }).then(function(res) {
        res.data.forEach(function(articulo, i) {
          let inv = articulo.inventario.find(
            inv =>
              inv.almacen_id.codigo == self.formData.almacen_id.codigo &&
              inv.wt == (self.formData.wt ? 1 : 0)
          );
          let unidades_teorico = (inv && inv.unidad) || 0;
          let peso_teorico = (inv && inv.peso) || 0;
          res.data[i].unidades_teorico = unidades_teorico;
          res.data[i].peso_teorico = peso_teorico;
          res.data[i].unidades_real = 0;
          res.data[i].peso_real = 0;
          res.data[i].unidades_dif = -unidades_teorico;
          res.data[i].peso_dif = -peso_teorico;
          res.data[i].articulo_id = {
            codigo: articulo.codigo,
            nombre: articulo.nombre
          };
          delete res.data[i].inventario;
          delete res.data[i].codigo;
        });
        self.$set(self.formData, "lineas", res.data);
      });
    },
    onChangeSeleccion() {
      var self = this;
      self.$set(self.formData, "familia_id", null);
      self.$set(self.formData, "seccion_id", null);
      self.$set(self.formData, "proveedor_id", null);
      self.$set(self.formData, "marca_id", null);
      self.$set(self.formData, "lineas", []);
      if (self.formData.seleccion == "global") self.refreshLineas();
      let colDataArticulo = self.$refs.lineas.field.columnsData.find(
        c => c.name == "articulo_id"
      );
      let colDataEan13 = self.$refs.lineas.field.columnsData.find(
        c => c.name == "ean13"
      );
      if (colDataArticulo) {
        if (self.formData.seleccion == "articulo_id") {
          colDataArticulo.readOnly = false;
        } else {
          colDataArticulo.readOnly = true;
        }
      }
      if (colDataEan13) {
        if (self.formData.seleccion == "articulo_id") {
          colDataEan13.readOnly = false;
        } else {
          colDataEan13.readOnly = true;
        }
      }
    },
    rendererRojoNegativo(instance, td, row, col, prop, value, cellProperties) {
      let h = instance;
      if (value != 0) td.style.color = "red";
      else td.style.color = "grey";
      Handsontable.renderers.NumericRenderer.apply(this, arguments);
    }
  },
  mounted() {
    var self = this;

    self.$refs["main-tabs"].$refs.toolbar.addEventListener("dblclick", function(
      e
    ) {
      if (self.app.session.mode == "a") return;
      if (e.target == self.$refs["main-tabs"].$refs.toolbar) {
        if (self.mode != "edit" && self.mode != "new" && self.mode != "search")
          return;
        self.$set(self.formData, "wt", !parseInt(self.formData.wt) ? 1 : 0);
        self.$emit("change", "wt", "edit");
        if (self.mode == "search") self.setFilter(self.getFilter());
      }
    });

    self.$refs.lineas.field.hotInstance.addHook("afterChange", function(
      changes,
      source
    ) {
      if (source == "loadData") return;
      if (!changes) return;
      var h = this;
      changes.forEach(function(change, index) {
        var row = change[0];
        var prop = change[1];
        var oldVal = change[2];
        var newVal = change[3];
        let val = c => h.getDataAtRowProp(row, c) || 0;
        if (prop == "unidades_real" || prop == "unidades_teorico") {
          h.getSourceData()[row]["unidades_dif"] =
            val("unidades_real") - val("unidades_teorico");
          self.$refs.lineas.field.renderH();
        }
        if (prop == "peso_real" || prop == "peso_teorico") {
          h.getSourceData()[row]["peso_dif"] =
            val("peso_real") - val("peso_teorico");
          self.$refs.lineas.field.renderH();
        }

        if ((prop == "ean13" || prop == "articulo_id") && oldVal != newVal) {
          window.DB.getList("articulo", {
            limit: 1,
            filter: [prop == "articulo_id" ? "codigo" : "ean13", "=", newVal],
            depth: 3,
            fields: [
              "ean13",
              "codigo",
              "nombre",
              {
                name: "inventario",
                fields: [
                  "wt",
                  "unidad",
                  "peso",
                  { name: "almacen_id", fields: ["codigo"] }
                ]
              }
            ]
          }).then(function(res) {
            if (parseInt(res.count)) {
              let articulo = res.data[0];
              let inv = articulo.inventario.find(
                inv =>
                  inv.almacen_id.codigo == self.formData.almacen_id.codigo &&
                  inv.wt == (self.formData.wt ? 1 : 0)
              );
              let unidades_teorico = (inv && inv.unidad) || 0;
              let peso_teorico = (inv && inv.peso) || 0;
              articulo.unidades_teorico = unidades_teorico;
              articulo.peso_teorico = peso_teorico;
              articulo.unidades_real = 0;
              articulo.peso_real = 0;
              articulo.unidades_dif = -unidades_teorico;
              articulo.peso_dif = -peso_teorico;
              articulo.articulo_id = {
                codigo: articulo.codigo,
                nombre: articulo.nombre
              };
              delete articulo.inventario;
              delete articulo.codigo;
              h.getSourceData()[row] = articulo;
              self.$refs.lineas.field.renderH();
            } else {
              self.app.toast("No se ha encontrado el artículo", "error");
            }
          });
        }
      });
    });
  }
};
</script>